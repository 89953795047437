export const bzPartnerCategories = []

export const bzPartners = [
    {
        "level": 2,
        "description": "Accor is a world leading hospitality group offering experiences across more than 110 countries in 5400 properties, 10000 food & beverage venues, wellness facilities or flexible workspaces. The Group has one of the industry’s most diverse hospitality ecosystems, with over 40 hotel brands from luxury to economy, as well as Lifestyle.",
        "eventId": 468284,
        "created": "2023-03-15T12:34:24.000+0000",
        "visible": true,
        "website": "https://all.accor.com/usa/index.en.shtml",
        "logo": "/legacy/partners/urntdhpug4hx4npzjgzq.png",
        "name": "Accor",
        "id": 444912,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "With more than 70 offices in over 40 countries and 4,800+ lawyers worldwide, CMS offers deep local market understanding with a global perspective.\nWe have the largest coverage in CEE/SEE. With 17 offices and 30+ years of experience advising on all aspects in the hotel and leisure industry, we offer owners, investors, lenders and operators expert solutions across the region and beyond. \nAs a Future Facing firm, we take a bold, dynamic and agile approach to help clients face the future with confidence. \n",
        "eventId": 468284,
        "created": "2023-04-17T10:28:00.000+0000",
        "visible": true,
        "website": "https://cms.law/en/hrv/",
        "logo": "/legacy/partners/rzjlr5pegb4p3v1drg28.png",
        "name": "CMS",
        "id": 450393,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "As leading experts in hotel and hospitality real estate, Colliers can support your investment across the entirety of its lifecycle. Our global reach, coupled with localised expertise, means that whether you’re a hotel investor, developer operator or financier, our dedicated and highly experienced team will provide the support you need.",
        "eventId": 468284,
        "created": "2023-06-29T07:34:12.000+0000",
        "visible": true,
        "website": "https://www.colliers.com/en",
        "logo": "/legacy/partners/p9i2iqmhhzbbatt8drfl.png",
        "name": "Colliers",
        "id": 465300,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Cushman & Wakefield is a leading global real estate services firm that delivers exceptional value for real estate occupiers and owners. \nOur hospitality team combines real estate knowledge with practical hotel expertise that adds value for hotel investors, owners, operators, developers and financiers.\ncushmanwakefield.com | Linkedin.  \n",
        "eventId": 468284,
        "created": "2023-04-17T10:26:29.000+0000",
        "visible": true,
        "website": "https://www.cushmanwakefield.com/en",
        "logo": "/legacy/partners/jy16stitshddyexa6mr8.jpeg",
        "name": "Cushman & Wakefield",
        "id": 450392,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Local is beautiful. Wherever you may be. \nDanubius Hotels boasts of prestigious properties in Hungary, and an excellent city hotel in UK, London.\nOffering outstanding individual and group business services, unique conferencing facilities, all inclusive and family-friendly services. \nWith our 50 years of experience in hotel operation we believe that guests and employees are equally important to us.\n",
        "eventId": 468284,
        "created": "2023-06-01T13:59:23.000+0000",
        "visible": true,
        "website": "https://www.danubiushotels.com/",
        "logo": "/legacy/partners/ftfzif6vohw9f1w0w2a6.jpeg",
        "name": "Danubius Hotels",
        "id": 459730,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Ensana Health Spa Hotels - Where natural medicine meets hospitality\n\nEnsana is Europe’s largest operator of health spas, specializing in fusing local natural healing resources with modern medical. The portfolio of properties within Ensana is deliberately broad, to offer hotels that suit every budget and offer the best value, while consistently delivering the highest quality treatments. The portfolio includes a wide range of historic and beautiful properties\n",
        "eventId": 468284,
        "created": "2023-06-20T11:27:27.000+0000",
        "visible": true,
        "website": "https://ensanahotels.com/",
        "logo": "/legacy/partners/q6v81alrblnjm86jik1s.png",
        "name": "Ensana Hotels",
        "id": 464021,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "ENTERPRISE GREECE is the oﬃcial investment and trade promotion agency of the Greek state, under the auspices of the Ministry of Foreign Affairs. Our mission is to attract foreign investment and to promote Greek products and services abroad. We prioritize businesses by showcasing Greece's outstanding investment opportunities to foreign investors and promoting the local high-quality exports.",
        "eventId": 468284,
        "created": "2023-06-02T10:35:37.000+0000",
        "visible": true,
        "website": "https://www.enterprisegreece.gov.gr/en/",
        "logo": "/legacy/partners/bgf5oabutyaewd5ke3yk.png",
        "name": "Enterprise Greece",
        "id": 459825,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Hilton is a leading global hospitality company with a portfolio of 19 world-class brands comprising more than 7,100 properties and more than 1.1 million rooms, in 123 countries and territories. ",
        "eventId": 468284,
        "created": "2023-03-14T10:04:31.000+0000",
        "visible": true,
        "website": "https://www.hilton.com/en/",
        "logo": "/legacy/partners/qnzzlhqgriupw9attijf.jpeg",
        "name": "Hilton",
        "id": 444418,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "At Horwath HTL, our focus is one hundred percent on hotel, tourism and leisure consulting. Our services cover every aspect of hotel real estate, tourism and leisure development.\nA global brand with 52 offices in 38 countries, we have successfully carried out over 40,000 assignments for private and public clients.\nWe are part of the Crowe Global network, a top 10 accounting and financial services network. We are the number one choice for companies and financial institutions looking to invest and develop in the industry.\nWe are Horwath HTL, the global leader in hotel, tourism and leisure consulting.\n",
        "eventId": 468284,
        "created": "2023-06-21T07:50:48.000+0000",
        "visible": true,
        "website": "https://horwathhtl.com/",
        "logo": "/legacy/partners/b3rtfbegr2zrs4ugceok.png",
        "name": "Horwath HTL",
        "id": 464215,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Invest Cyprus is the Investment Promotion Authority of Cyprus, dedicated to attracting & facilitating foreign direct investment into the country. Invest Cyprus supports businesses and investors providing facilitation and after care services when it comes to setting up your business, relocating your staff or expanding your investment in Cyprus.",
        "eventId": 468284,
        "created": "2023-08-10T14:12:34.000+0000",
        "visible": true,
        "website": "https://www.investcyprus.org.cy/",
        "logo": "/legacy/partners/kpxrd3lsg5zxytqhkwg4.jpeg",
        "name": "Invest Cyprus",
        "id": 472688,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "With campuses located in the exclusive resort destinations of Crans-Montana, Switzerland, and Marbella, Spain, Les Roches is an international hospitality business school dedicated to shaping the industry’s future talents. Les Roches offers an exceptional portfolio of undergraduate, graduate, and executive education programs; each combining real-world knowledge with innovative flair.",
        "eventId": 468284,
        "created": "2023-08-16T09:36:27.000+0000",
        "visible": true,
        "website": "https://lesroches.edu/",
        "logo": "/legacy/partners/lcuysdeuolx2tor61jz5.png",
        "name": "Les Roches",
        "id": 473127,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "‘We conceived LEVA Hotels as a lean hospitality management company, with a strong focus on converting and uplifting existing hotels into upscale, lifestyle properties with soft modifications. Unlike traditional large chains, LEVA provides innovative, flexible management solutions that are led by a team of entrepreneurial and ‘hands-on’ hoteliers whose sole aim is to deliver maximum financial returns to our owners, and consistency in guest satisfaction. The brand is strongly focused on growing in Africa, the Middle East, and Europe - mainly in Egypt, KSA, Morocco, Qatar, Oman, Sri Lanka, and Greece.’",
        "eventId": 468284,
        "created": "2023-07-04T12:51:57.000+0000",
        "visible": true,
        "website": "https://stayleva.com/",
        "logo": "/legacy/partners/jhwcwm2ha12wovbcayq6.png",
        "name": "LEVA Hotels",
        "id": 466402,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "eventId": 468284,
        "created": "2023-04-20T12:17:19.000+0000",
        "visible": true,
        "website": "https://www.louvrehotels.com/en-us/",
        "logo": "/legacy/partners/ftmfv0zqwiv6coipoxrh.jpeg",
        "name": "Louvre Hotels Group",
        "id": 452137,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Marriott International, Inc. (NASDAQ: MAR) is based in Bethesda, Maryland, USA, and encompasses a portfolio of nearly 8,600 properties under 31 leading brands spanning 139 countries and territories. Marriott operates and franchises hotels and licenses vacation ownership resorts all around the world. The company offers Marriott Bonvoy®, its highly awarded travel program. For more information, please visit our website at www.marriott.com. In addition, connect with us on Facebook and @MarriottIntl on Twitter and Instagram.",
        "eventId": 468284,
        "created": "2022-04-05T06:48:42.000+0000",
        "visible": true,
        "website": "https://hotel-development.marriott.com/",
        "logo": "/legacy/partners/tnr2scqneesftgogyeof.png",
        "name": "Marriott International",
        "id": 379525,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Radisson Hotel Group has 1,150+ hotels in operation or under development in EMEA and APAC, growing its portfolio across its ten relevant brands ranging from midscale to lifestyle luxury. Sustainability is a priority for the Group, aiming for Net Zero by 2050 and verifying hotels on Hotel Sustainability Basics.",
        "eventId": 468284,
        "created": "2023-03-14T10:06:17.000+0000",
        "visible": true,
        "website": "https://www.radissonhotels.com/en-us/",
        "logo": "/legacy/partners/lru4x67uakol0y6tkjvq.jpeg",
        "name": "Radisson Hotel Group",
        "id": 444420,
        "type": "SPONSOR"
    },
    {
        "level": 2,
        "description": "Wyndham Hotels & Resorts is the world’s largest hotel franchising company by number of properties, with approximately 9,100 hotels across over 95 countries. Through its network of over 843,000 rooms appealing to the everyday traveller, Wyndham commands a leading presence in the economy and midscale segments of the lodging industry.",
        "eventId": 468284,
        "created": "2023-04-14T10:28:27.000+0000",
        "visible": true,
        "website": "https://www.wyndhamhotels.com/",
        "logo": "/legacy/partners/sb5idxwqndxqmifqqum7.png",
        "name": "Wyndham Hotels & Resorts",
        "id": 450266,
        "type": "SPONSOR"
    },
    {
        "level": 4,
        "description": "BWH Hotels is a leading, global hospitality enterprise comprised of three hotel companies, including WorldHotels™, Best Western® Hotels & Resorts and SureStay® Hotels. The global enterprise boasts approximately 4,300 hotels in over 100 countries and territories worldwide*. With 19 brands across every chain scale segment, from economy to luxury, BWH Hotels suits the needs of developers and guests in every market.\n*Numbers are approximate, may fluctuate, and include hotels currently in the development pipeline.\n",
        "eventId": 468284,
        "created": "2023-07-28T07:16:22.000+0000",
        "visible": true,
        "website": "http://www.bwhhotelgroup.de/",
        "logo": "/legacy/partners/iod6wvvnhu9eob5uu3ve.png",
        "name": "BWH Hotels",
        "id": 470960,
        "type": "SPONSOR"
    },
    {
        "level": 5,
        "description": "Our job is to make the world a cleaner place by using innovative technology for warewashing, cleaning, disinfection and food waste treatment.\nMEIKO is consistently advancing in this direction. We use sustainable production methods, focus on quality rather than quantity and we create innovative appliances to reduce energy and water consumption. Our blue planet needs better than half solutions. \n",
        "eventId": 468284,
        "created": "2023-06-01T11:47:23.000+0000",
        "visible": true,
        "website": "https://www.meiko.info/en/",
        "logo": "/legacy/partners/c7ypi0qkkvxvfqmraiwd.jpeg",
        "name": "Meiko",
        "id": 459715,
        "type": "SPONSOR"
    },
    {
        "level": 5,
        "description": "The Ronge & Partner Group offers consulting, planning and projecting of commercial kitchens in out-of-home catering for international clients. The many years of experience in the luxury hotel industry make it possible to implement the requirements of the customers, both for investors and operators, professionally and precisely. We accompany you in your individual project from concept development to planning in the BIM standard to final acceptance.",
        "eventId": 468284,
        "created": "2023-07-05T13:02:50.000+0000",
        "visible": true,
        "website": "http://www.ronge-partner.at",
        "logo": "/legacy/partners/nw4vb3pg18hw07ywb6hh.jpeg",
        "name": "Ronge & Partner Group",
        "id": 466657,
        "type": "SPONSOR"
    },
    {
        "level": 6,
        "description": "Power your investment, portfolio and profi¬tability strategies with the world’s most comprehensive hospitality data and analytics. Combined with the commercial real estate intelligence of CoStar, STR Benchmarking provides visibility into the full hotel lifecycle from acquisition to operations to divestment.",
        "eventId": 468284,
        "created": "2023-04-26T09:23:18.000+0000",
        "visible": true,
        "website": "https://str.com/",
        "logo": "/legacy/partners/tv1meupgmgerrug6ok1o.png",
        "name": "STR",
        "id": 453232,
        "type": "SPONSOR"
    },
    {
        "level": 7,
        "eventId": 468284,
        "created": "2023-07-19T12:26:44.000+0000",
        "visible": true,
        "website": "https://hamagroup.org/",
        "logo": "/legacy/partners/gqcdwatbisj1lphwwfp9.png",
        "name": "Hospitality Asset Managers Association",
        "id": 469600,
        "type": "SPONSOR"
    },
    {
        "level": 8,
        "eventId": 468284,
        "created": "2023-08-22T11:54:54.000+0000",
        "visible": true,
        "website": "https://www.travelandtourworld.com/",
        "logo": "/legacy/partners/eofiihy6ftbz4phktdo6.jpeg",
        "name": "Travel and Tour World",
        "id": 474053,
        "type": "SPONSOR"
    },
    {
        "level": 8,
        "eventId": 468284,
        "created": "2023-08-22T07:15:30.000+0000",
        "visible": true,
        "website": "https://europaproperty.com/",
        "logo": "/legacy/partners/ya529iofchw2fglhfik7.png",
        "name": "Europa Property",
        "id": 474035,
        "type": "SPONSOR"
    },
    {
        "level": 8,
        "eventId": 468284,
        "created": "2023-08-22T07:12:44.000+0000",
        "visible": true,
        "website": "https://fivestar-hospitality.ro/",
        "logo": "/legacy/partners/ce1sw9hqozxrt299c1jw.jpeg",
        "name": "Fivestar Hospitality",
        "id": 474034,
        "type": "SPONSOR"
    },
    {
        "level": 8,
        "eventId": 468284,
        "created": "2023-08-22T07:09:56.000+0000",
        "visible": true,
        "website": "https://europe-re.com/",
        "logo": "/legacy/partners/uzepzi4rovzxheatnodt.png",
        "name": "Europe Real Estate",
        "id": 474033,
        "type": "SPONSOR"
    }
]